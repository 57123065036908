import { Flex } from '@chakra-ui/react';
import { CSpinner } from '@coreui/react-pro';
import { format } from "date-fns";
import React from 'react';

export const MainSpinner = () => {
    return <CSpinner color="primary" variant="grow" />
}
export const CenteredSpinner = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <MainSpinner />
        </Flex>
    );
};
export const LoadingSpinner = () => {
    return (
        <Flex
            justifyContent="center"
            alignItems="center"
        >
            <MainSpinner />
        </Flex>
    );
};

export const ConvertTimeFormat = (date) => {

    const dateObject = new Date(date);
    return format(dateObject, 'yyyy-MM-dd');

}

export const TimeFormate = (timeString) => {
    const timePart = timeString.split(' ')[0];
    return timePart
}


export const SortedTimes = (times) => {
    return times.sort((a, b) => {
        const timeA = new Date(`1970-01-01T${a}Z`);
        const timeB = new Date(`1970-01-01T${b}Z`);
        return timeA - timeB;
    });
}


export const convertToMinutes = (duration) => {
    const regex = /P(?:([0-9]+)D)?T(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9]+)S)?/;
    const matches = duration.match(regex);

    if (!matches) return 0;

    const days = parseInt(matches[1] || 0, 10);
    const hours = parseInt(matches[2] || 0, 10);
    const minutes = parseInt(matches[3] || 0, 10);
    const seconds = parseInt(matches[4] || 0, 10);

    // Convert all time units to minutes
    return days * 24 * 60 + hours * 60 + minutes + Math.floor(seconds / 60);
}