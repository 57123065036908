export const commonActions = {
    SET: "SET",
    sidebarShow: "sidebarShow",
    asideShow: "asideShow",
    sidebarUnfoldable: "sidebarUnfoldable",
    theme: "theme",
    MasterRefresh: "MasterRefresh",
    InviteAccount: "InviteAccount",
    InviteAccountURL: "InviteAccountURL",
    IsOnBoarding: "IsOnBoarding",
    GenerateTimeIndex: "GenerateTimeIndex",
};
