import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';



const ProtectedRoute = ({ Component }) => {

  const { IsAuthenticated } = useSelector((state) => state.UserCred)

  if (IsAuthenticated === false) {
    return <Navigate to="/login" replace />;
  }
  return <Component />;
};

ProtectedRoute.propTypes = {
  Component: PropTypes.elementType.isRequired, // Validate that Component is a React component
};


export default ProtectedRoute;